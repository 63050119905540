<template>
  <div
    ref="chart"
    style="width: 100%; height: 100%"
    v-resize="adapterScreen"
  ></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "BenchTaskPieChart",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => {
        return {
          legend: {
            avoidLabelOverlap: true,
            orient: "vertical",
            align: "left",
            icon: "circle",
            top: "5%",
            selectedMode: true,
          },
          grid: { containLabel: true },
          tooltip: { trigger: "item" },
          series: {
            shadowBlur: true,
            shadowColor: true, //透明：fasle,正常:true
            minShowLabelAngle: 0,
          },
        };
      },
    },
  },
  data() {
    return {
      fontPie: null
    }
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.chart);
    },
    setOption() {
      // console.log(this.config, "配置项");
      this.chart.setOption({
        // avoidLabelOverlap（是否启用防止标签重叠策略，默认默认开启，默认是true ，可以不设置，但是不能设置成false）
        avoidLabelOverlap: this.configPie.legend.avoidLabelOverlap,
        legend: {
          show: Object.keys(this.data).length > 0,
          orient: this.configPie.legend.orient,
          left: "right",
          align: this.configPie.legend.align,
          icon: this.configPie.legend.icon,
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 10,
          top: this.configPie.legend.top,
          selectedMode: this.configPie.legend.selectedMode,
        },
        grid: {
          containLabel: this.configPie.grid.containLabel,
        },
        tooltip: {
          trigger: this.configPie.tooltip.trigger,
          formatter: (params) => {
            const str =
              "status：" +
              params.data.name +
              "</br>" +
              "人数：" +
              params.data.value +
              "</br>" +
              "所占总人数百分比：" +
              params.percent +
              "%" +
              "</br>";
            return str;
          },
        },
        series: [
          {
            type: "pie",
            minShowLabelAngle: this.configPie.series.minShowLabelAngle,
            radius: this.fontPie * 4,
            center: ["45%", "45%"], //响应处理
            data: this.data.map(({ countStatus, status }) => ({
              value: countStatus,
              name: status,
            })),
            label: {
              fontSize: "16",
              normal: {
                formatter: "\n{b}\n{c}人\n{d}%",
              },
            },
            textStyle: { color: "#787878", fontSize: "16" }, //响应处理
            emphasis: {
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
              },
              scale: true,
              scaleSize: 10,
            },
          },
        ],
      });
    },
    // 适配
    adapterScreen() {
      console.log(this.fontPie, "单个尺寸");
      this.fontPie = (this.$refs.chart.offsetWidth / 100) * 3;
      const adpOption = {
        legend: {
          itemWidth: this.fontPie / 2.5,
          itemHeight: this.fontPie / 2.5,
          itemGap: this.fontPie / 1.7,
          textStyle: { fontSize: this.fontPie / 1.7 },
        },
        series: [
          {
            label: {
              fontSize: this.fontPie / 1.7,
            },
            radius: this.fontPie * 4,
            center: ["40%", "50%"],
            textStyle: { fontSize: this.fontPie / 1.7 },
            emphasis: {
              itemStyle: {
                shadowBlur: this.configPie.series.shadowBlur
                  ? this.fontPie / 2
                  : 0,
                shadowColor: this.configPie.series.shadowColor
                  ? "#eee"
                  : "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.chart.setOption(adpOption);
      this.chart.resize();
    },
  },
  computed: {
    configPie() {
      let defaultConfig = {
        legend: {
          avoidLabelOverlap: true,
          orient: "vertical",
          align: "left",
          icon: "circle",
          top: "5%",
          selectedMode: true,
        },
        grid: { containLabel: true },
        tooltip: { trigger: "item" },
        series: {
          shadowBlur: true,
          shadowColor: true, //透明：fasle,正常:true
          minShowLabelAngle: 0,
        },
      };
      console.log(Object.assign(defaultConfig, this.config), "合并的对象");
      return Object.assign(defaultConfig, this.config);
    },
    // fontPie() {
    //   console.log('chart:',this.$refs.chart.offsetWidth)
    //   return (this.$refs.chart.offsetWidth / 100) * 3;
    // },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.setOption();
      },
    },
  },
  mounted() {
    this.init();
    this.setOption();
    this.adapterScreen();
  },
};
</script>
