<template>
  <div class="taskShow">
    <div class="benchTaskStart">
      <div class="topFilter">
        <div class="text">人员总数：{{ total }}</div>
        <div class="btnSpace">
          <a-button class="newBtn" type="primary" @click="resetClick">
            重置
          </a-button>
          <a-button class="newBtn" type="primary" @click="newBenchTask" v-auth="['system:benchTask:lmBenchTaskDetail']"
            >任务看板
          </a-button>
        </div>
      </div>
      <div class="assignment">
        <!--        style="min-width: 800px"-->
        <!-- <div ref="lmStaffBoard" style="min-width: 600px; height: 100%"></div> -->
        <!-- 饼图抽取 mx.z -->
        <div :style="chartContentStyle">
          <PieChart
            ref="benchTaskPie"
            :data="pieConfig.pieDate"
            :config="pieConfig.config"
          />
        </div>

        <div id="benchTaskInfo">
          <a-table
            :columns="columns"
            rowKey="id"
            :data-source="dataSource"
            :scroll="{ y: heightGap }"
            :pagination="false"
          >
            <template #action="{ record }">
<!--              <span class="operationLink underline" @click="openEmpModal(record)" v-auth="['system:benchTask:lmOpenEmpModal']"
                >查看顾问信息
              </span>-->
              <ActionBtn v-bind="record" :btnList="btnList" @IdcardOutlined="openEmpModal(record)" />
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
  <emp-info
    v-if="isShowingEmpInfo"
    :isShowingEmpInfo="isShowingEmpInfo"
    :userDataList="userDataList"
    :getId="getId"
    @close-emp-info="closeEmpInfo"
    :showworkExperienceCol="showworkExperienceCol"
  />
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  onBeforeUnmount,
  reactive,
  onActivated,
} from "vue";
// import * as echarts from "echarts";
import { useRouter } from "vue-router";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import EmpInfo from "@/components/EmpInfo.vue";
import PieChart from "./component/benchTaskPieChart.vue";
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: '个人信息',
    icon: 'IdcardOutlined',
    auth: ['system:benchTask:lmOpenEmpModal']
  },
]

const columns = [
  {
    title: "Status",
    dataIndex: "status",
    ellipsis: true,
  },
  {
    title: "Name",
    dataIndex: "staffName",
    ellipsis: true,
  },
  {
    title: "Task",
    dataIndex: "countTask",
    ellipsis: true,
  },
  {
    title: "Doing",
    dataIndex: "countDoing",
    ellipsis: true,
  },
  {
    title: "Done",
    dataIndex: "countDone",
    ellipsis: true,
  },
  {
    title: "Expired",
    dataIndex: "countExpired",
    ellipsis: true,
  },
  {
    title: "Action",
    dataIndex: "Action",
    slots: { customRender: "action" },
    width: 80,
    fixed: "right",
  },
];

export default defineComponent({
  name: "benchTask",
  components: {
    EmpInfo,
    PieChart,
    ActionBtn
  },
  setup() {
    const router = useRouter();
    const total = ref();
    const { $api } = useGlobalPropertyHook();
    const dataSource = ref([]);
    const showworkExperienceCol = ref(true);
    const lmStaffBoard = ref(null);
    const benchTaskPie = ref(null); //pie组件的ref
    const pieConfig = reactive({
      config: { series: { minShowLabelAngle: 0.1, shadowColor: false } },
      pieDate: [], //饼图数据
    });
    const chartContentStyle = reactive({
      width: '',
      height: '100%',
    });
    // 更改页面大小时重新布局饼图模块
    const getChartContentSize = () => {
      const sideNavWidth = document.getElementsByClassName('side-nav-left')[0].clientWidth
      const chartContentWidth = document.body.clientWidth
      chartContentStyle.width = (chartContentWidth - sideNavWidth)/2 + 'px'
    }
    const newBenchTask = () => {
      router.push({ path: "/benchTask/lmBenchTaskDetail" });
    };

    const getTableData = (status) => {
      const data = {
        staffStatus: status,
      };
      $api.getTaskStatus(data).then((res) => {
        dataSource.value = res.data;
      });
    };

    const getCircleNum = () => {
      $api.getPerNum().then((res) => {
        total.value = res.data.total;
        pieConfig.pieDate = res.data?.staffStatusList || []; //给饼图列表赋值
        // window.onresize = function () {
        //   myChart.resize();
        // };
        // const option = {
        //   // avoidLabelOverlap: false,
        //   legend: {
        //     orient: "vertical",
        //     left: "right",
        //     top: "6%",
        //     selectedMode: false,
        //   },
        //   tooltip: {
        //     trigger: "item",
        //     formatter: (params) => {
        //       const str =
        //         "status:" +
        //         params.data.name +
        //         "</br>" +
        //         "人数:" +
        //         params.data.value +
        //         "</br>" +
        //         "所占总人数百分比:" +
        //         params.percent +
        //         "%" +
        //         "</br>";

        //       return str;
        //     },
        //   },
        //   series: [
        //     {
        //       type: "pie",
        //       minShowLabelAngle: 0.1, //传
        //       radius: "50%",
        //       center: ["43%", "45%"],
        //       data: (res.data?.staffStatusList || []).map(
        //         //data传过去
        //         ({ countStatus, status }) => ({
        //           value: countStatus,
        //           name: status,
        //         })
        //       ),
        //       label: {
        //         normal: {
        //           formatter: "\n{b}\n{c}人\n{d}%",
        //         },
        //       },

        //       textStyle: { color: "#787878", fontSize: "16" },
        //       emphasis: {
        //         itemStyle: {
        //           shadowBlur: 0,
        //           shadowOffsetX: 0,
        //           shadowColor: "rgba(0, 0, 0, 0.5)", //传false
        //         },
        //         scale: true,
        //         scaleSize: 10,
        //       },
        //     },
        //   ],
        // };
        // // 使用刚指定的配置项和数据显示图表。
        // myChart.setOption(option, true);
      });
    };
    const isShowingEmpInfo = ref(false);
    const userDataList = reactive({});
    const getId = ref();
    const openEmpModal = (item) => {
      getId.value = item.id;
      $api.getOneUser(item.id).then((res) => {
        Object.assign(userDataList, res.data);
        if (res.code == 200) {
          isShowingEmpInfo.value = true;
        }
      });
    };

    const closeEmpInfo = () => {
      isShowingEmpInfo.value = false;
    };

    const heightGap = ref();

    let myChart;
    let activeIndex;

    const resetClick = () => {
      myChart.dispatchAction({
        type: "downplay",
        dataIndex: activeIndex,
      });
      activeIndex = undefined;
      getCircleNum();
      getTableData();
    };

    let chooseStatus;
    window.addEventListener("resize", getChartContentSize);
    onMounted(() => {
      console.log(benchTaskPie.value, "这是饼图组件");
      myChart = benchTaskPie.value.chart;
      heightGap.value =
        document.body.clientHeight -
        document.querySelector(".ant-table").getBoundingClientRect().top -
        document.querySelector(".ant-table-thead").offsetHeight -
        100;
      // myChart = echarts.init(lmStaffBoard.value);
      myChart.on("click", function (params) {
        if (
          params.componentSubType === "pie" &&
          params.componentType === "series"
        ) {
          if (activeIndex !== undefined) {
            myChart.dispatchAction({
              type: "downplay",
              dataIndex: activeIndex,
            });
          }
          activeIndex = params.dataIndex;
          myChart.dispatchAction({
            type: "highlight",
            dataIndex: params.dataIndex,
          });
          chooseStatus = params.data.name;
          getTableData(chooseStatus);
        }
      });
      getChartContentSize()
    });
    onActivated(() => {
      getCircleNum();
      getTableData(chooseStatus);
    });
    onBeforeUnmount(() => {
      myChart.off("click");
      // @todo
      if(myChart.value ) myChart.value.removeAttribute("_echarts_instance_");
      if(lmStaffBoard.value) lmStaffBoard.value.removeAttribute("_echarts_instance_");

      // document
      //   .getElementById("benchTaskInfo")
      //   .removeAttribute("_echarts_instance_");
    });

    return {
      lmStaffBoard,
      total,
      newBenchTask,
      openEmpModal,
      closeEmpInfo,
      isShowingEmpInfo,
      userDataList,
      getId,
      columns,
      dataSource,
      showworkExperienceCol,
      resetClick,
      heightGap,
      pieConfig,
      benchTaskPie,
      chartContentStyle,
      btnList
    };
  },
});
</script>

<style lang="less" scoped>
::v-deep {
  .ant-card-body {
    width: 260px;
  }
}

.taskShow {
  height: calc(100% - 15px);
  background: #fff;
  margin: 5px 10px 10px;

  .benchTaskStart {
    display: flex;
    flex-direction: column;
    height: calc(100%);

    .topFilter {
      height: 6%;
      margin: 20px 20px 10px;
      display: flex;
      justify-content: space-between;

      .text {
        font-size: 22px;
        font-weight: 600;
      }

      .newBtn {
        margin-right: 10px;
      }
    }

    .assignment {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      margin-right: 10px;
    }
  }
}

.searchEmp {
  cursor: pointer;
  padding: 0px 2px;
  height: 25px;
}

.moreAss {
  margin-right: 5px;
}

#benchTaskInfo {
  margin-left: 4px;
  flex: 1;
  height: 100%;
  padding-top: 50px;

  :deep(.ant-table-body) {
    overflow: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }
}

:deep(.ant-card) {
  display: flex;
}

:deep(.ant-card-head) {
  min-width: 17px;
}

:deep(.ant-list-grid .ant-col > .ant-list-item) {
  max-height: 98px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

.col-eye {
  color: green;

  &:hover {
    cursor: pointer;
  }
}

.threeInfos {
  width: 550px;
  overflow: hidden;

  .ant-list-item {
    display: block;
  }
}

:deep(.ant-row) {
  margin-bottom: 4px;
}

:deep(.ant-card-body) {
  flex: 1;
}

:deep(.ant-btn-link) {
  padding: 0px;
}
</style>
